<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_assegna_campo">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assegnazione campi</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div
            class="mx-auto my-auto text-center py-5 my-5"
            v-if="isLoadingDetail"
          >
            <div class="spinner-border text-gray-600" role="status"></div>
            <span class="text-gray-600 ps-3 fs-2">Loading</span>
          </div>
          <div class="pb-6 ps-6 d-flex" v-if="!isLoadingDetail && impianto">
            Impianto:
            <h5 class="fw-bold text-uppercase ps-4 pe-4">
              {{ impianto.denominazione }}
            </h5>
          </div>
          <div class="card-body" v-if="!isLoadingDetail && impianto.campi">
            <div class="ps-5 pe-5 fw-bold">
              <div
                class="form-check row pb-4"
                v-for="(campo, index) in impianto.campi"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  :id="'flexCheckChecked' + index"
                  v-model="campo.checked"
                  :checked="campo.selezionato"
                  @click="campo.selezionato = !campo.selezionato"
                  :disabled="!campo.selezionabile"
                />
                <label
                  class="form-check-label"
                  :for="'flexCheckChecked' + index"
                  :class="
                    campo.disciplina.includes('(Campo dismesso)')
                      ? 'font-italic text-danger'
                      : ''
                  "
                >
                  {{ campo.nome_campo }}
                  <span v-if="campo.nome_campo"> - </span>
                  {{ campo.disciplina }}
                </label>
              </div>
            </div>
          </div>
          <div
            class="card-body text-center"
            v-if="!isLoadingDetail && !impianto.campi"
          >
            <span
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              style="align-self: center; white-space: normal !important"
            >
              l'impianto selezionato non ha campi associati</span
            >
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeModalEditAss"
          >
            Chiudi
          </span>
          <button
            v-if="impianto.campi"
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="assegnaCampi"
            :disabled="disableButton"
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";
import "vue3-date-time-picker/dist/main.css";
import { assegnazioneCampi } from "@/requests/addImpianto";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "AssegnazioneCampi",
  emits: ["loadImpiantiSocieta"],
  props: {
    impianto: {
      type: Array,
      required: true,
    },
    societa: {
      type: Object,
    },
    isLoadingDetail: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    const disableButton = ref(false);
    const isLoading = ref(false);

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione);

    const assegnaCampi = async () => {
      const idCampiString = ref("");
      props.impianto.campi.forEach((element) => {
        if (element.selezionato) {
          idCampiString.value = idCampiString.value + element.id + ",";
        }
      });
      disableButton.value = true;
      isLoading.value = true;
      const campiAdded = ref(false);
      await assegnazioneCampi({
        id_Societa: props.societa.id,
        id_Impianto: props.impianto.id,
        anno: stagione.value,
        cod_Affiliazione: props.societa.cod_affiliazione,
        campi: idCampiString.value.slice(0, -1),
      }).then((res) => (campiAdded.value = res));

      if (campiAdded.value.statusCode == 200) {
        Swal.fire({
          html: "Campi assegnati correttamente",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        emit("loadImpiantiSocieta");
        document.getElementById("closeModalEditAss").click();
        router.push(
          `/societa/dettaglio-societa/impianti-societa/${props.societa.id}`
        );
      } else {
        const strMsg = ref(
          campiAdded.value.data.message
            ? campiAdded.value.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        );
        Swal.fire({
          html: strMsg.value,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
      disableButton.value = false;
      isLoading.value = false;
    };
    return {
      assegnaCampi,
      disableButton,
      isLoading,
    };
  },
  components: { Loading },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}
.font-italic {
  font-style: italic;
}
</style>
